import React from "react"

const Input = ({
  label,
  name,
  value,
  onChange,
  placeholder,
  className,
  multiLine,
}) => (
  <div className={className}>
    {label && <label>{label}</label>}

    <div>
      {React.createElement(multiLine ? "textarea" : "input", {
        name: name,
        value: value,
        onChange: onChange,
        placeholder: placeholder,
      })}
    </div>
  </div>
)

export default Input
