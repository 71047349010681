import React, { useState } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Input from "../components/atoms/input"
import Dropdown from "../components/atoms/dropdown"

import {
  contact__label,
  contactForm__container,
  contactForm__input,
  contactForm__dropdown,
  contactForm__dropdown__disabledOption,
  contactForm__buttonsContainer,
  enabledButton,
} from "../sass/contact.module.scss"

const optionsList = [
  "Job Opportunity",
  "Engineering Services",
  "Make a website",
  "Report a bug",
  "Other",
]

const ContactForm = () => {
  const [nameValue, setNameValue] = useState("")
  const [emailValue, setEmailValue] = useState("")
  const [dropdownValue, setDropdownValue] = useState("")
  const [messageValue, setMessageValue] = useState("")
  const [isDefaultSelected, setIsDefaultSelected] = useState(true)

  const onNameChange = e => setNameValue(e.target.value)
  const onEmailChange = e => setEmailValue(e.target.value)
  const onDropdownChange = e => {
    setIsDefaultSelected(false)
    setDropdownValue(`GBOUFFARD.COM - ${e.target.value}`)
  }
  const onMessageChange = e => setMessageValue(e.target.value)

  const isValidEmail =
    !!emailValue && emailValue.indexOf("@") > 0 && emailValue.indexOf(".") > -1

  const disableSubmit =
    !nameValue || !isValidEmail || dropdownValue === "" || !messageValue

  const disableClear =
    !nameValue && !emailValue && dropdownValue === "" && !messageValue

  const clearForm = () => {
    setNameValue("")
    setEmailValue("")
    setDropdownValue("")
    setIsDefaultSelected(true)
    setMessageValue("")
  }

  return (
    <Layout>
      <SEO title="Guillaume Bouffard | Contact page" />
      <div className={contact__label}>Contact me</div>
      <form
        className={contactForm__container}
        name="contact"
        action="/success"
        method="POST"
        netlify-honeypot="bot-field"
        data-netlify="true"
      >
        <input type="hidden" name="form-name" value="contact" />
        <Input
          className={contactForm__input}
          label="Name"
          name="name"
          onChange={onNameChange}
          value={nameValue}
        />
        <Input
          className={contactForm__input}
          label="Email"
          name="email"
          onChange={onEmailChange}
          value={emailValue}
        />
        <Dropdown
          classname={
            dropdownValue === ""
              ? contactForm__dropdown__disabledOption
              : contactForm__dropdown
          }
          withDisabled={true}
          label="Subject"
          name="subject"
          options={optionsList}
          isDefaultSelected={isDefaultSelected}
          value={dropdownValue.substring(16)}
          onChange={onDropdownChange}
        />
        <Input
          className={contactForm__input}
          label="Message"
          name="message"
          onChange={onMessageChange}
          multiLine="true"
          value={messageValue}
        />
        <div className={contactForm__buttonsContainer}>
          <button
            type="submit"
            disabled={disableSubmit}
            className={disableSubmit ? null : enabledButton}
          >
            SEND
          </button>
          <button
            onClick={() => clearForm()}
            disabled={disableClear}
            className={disableClear ? null : enabledButton}
          >
            CLEAR
          </button>
        </div>
      </form>
    </Layout>
  )
}

export default ContactForm
